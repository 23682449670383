.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline-block;
  background-position: center;
  background-size: cover;
  background-image: url(../img/avatar.jpg);
  @media screen and (min-width: 992px) and (max-width: 1500px) {
    width: 35px;
    height: 35px;
  }
  @include media-breakpoint-down(md) {
    width: 30px;
    height: 30px;
  }
  &.tiny {
    width: 24px;
    height: 24px;
  }
  &.small {
    width: 35px;
    height: 35px;
    @media screen and (min-width: 992px) and (max-width: 1500px) {
      width: 28px;
      height: 28px;
    }
  }
  &.maxi {
    width: 125px;
    height: 125px;
    @include media-breakpoint-down(md) {
      width: 60px;
      height: 60px;
    }
  }
}

.badge {
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 20px;
  display: inline-block;
  &.orange {
    background-color: #ffdcd6;
    color: $color-orange;
  }
  &.lightblue {
    background-color: #f1f9fb;
    color: $primary;
  }
  &.badge-orange {
    background-color: $color-orange;
    color: white;
  }
  i,
  svg {
    margin-right: 10px;
  }
  &.badge-clean {
    background-color: white;
    font-size: 16px;
    font-weight: 400;
    margin-right: 15px;
    margin-bottom: 10px;
    text-transform: none;
    @media screen and (min-width: 992px) and (max-width: 1450px) {
      font-size: 14px;
    }
  }
  &.badge-grey {
    background-color: rgba(grey, 0.25);
  }
}

.icon {
  &-orange {
    color: $color-orange !important;
  }
  &-yellow {
    color: #fcac13;
  }
}

.back-link {
  font-size: 16px;
  font-weight: 600;
  @media screen and (min-width: 992px) and (max-width: 1450px) {
    font-size: 14px;
  }
  i,
  svg {
    margin-right: 10px;
    color: $color-orange;
    font-size: 18px;
  }
}

.file-type {
  background-color: $color-orange;
  color: white !important;
  display: inline-block;
  border-radius: 30px;
  padding: 4px 10px;
  line-height: 1;
  margin-right: 10px;
  font-size: 10px;
  &.exel {
    background-color: #1d6f42;
  }
  &.ppt {
    background-color: #c13b1b;
  }
  &.grey {
    background-color: grey;
  }
}

.blured {
  .card-body {
    filter: blur(5px);
  }
}

.btn {
  &-edit {
    padding: 0;
    color: $color-orange;
    outline: none;
    box-shadow: none !important;
    margin-left: 15px;
  }
  &-remove {
    color: red;
    transition: 0.3s ease all;
  }
  &-icon-left {
    i,
    svg {
      margin-right: 10px;
    }
  }
  &.btn-clean {
    background-color: transparent;
    padding: 0;
    color: $primary;
    font-weight: 400;
    border: 0;
    outline: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
      color: $color-orange;
    }
  }
  &.btn-orange {
    background-color: $color-orange;
    border-color: $color-orange;
    color: white;
    &:hover {
      background-color: white;
      color: $color-orange;
      border-color: $color-orange;
    }
  }
  &-small {
    font-size: 13px;
  }
}
.read-more {
  a {
    text-decoration: underline !important;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.color-orange {
  color: $color-orange;
}
.color-red {
  color: red;
}

.border-box {
  @media screen and (min-width: 992px) and (max-width: 1450px) {
    font-size: 14px;
    h4 {
      font-size: 17px;
    }
  }
}

.sort-wrapper {
  i,
  svg {
    color: $color-orange;
  }
}

.third-list {
  padding-left: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  li {
    flex: 0 0 25%;
    max-width: 25%;
    @include media-breakpoint-only(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1500px) {
  .ql-snow .ql-toolbar button svg,
  .quillWrapper .ql-snow.ql-toolbar button svg {
    width: 17px !important;
    height: 17px !important;
  }
}
