.auth-wrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    .auth-page-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        height: 100vh;
        &-inner {
            display: flex;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 50px 125px;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 1750px) {
                padding: 50px;
            }
            @media screen and (min-width: 1200px) and (max-width: 1500px) {
                flex: 0 0 70%;
                max-width: 70%;
            }
            @media screen and (max-width: 1200px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .auth-logo {
                max-width: 100px;
                margin-bottom: 35px;
            }
            h1 {
                margin-bottom: 25px;
            }
            .form {
                font-weight: 600;
            }
            .form-action {
                .btn {
                    width: 100%;
                    font-weight: 600;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
            }
            .form-control {
                margin-bottom: 15px;
                min-width: 325px;
            }
            .login-action {
                margin-top: 20px;
                &-item {
                    color: black;
                    font-weight: 600;
                }
            }
            .copyright {
                margin-top: 65px;
                color: lightgrey;
                font-weight: 600;
            }
        }
        &-image {
            flex: 0 0 50%;
            max-width: 50%;
            background-image: url(../img/ressort.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (min-width: 1200px) and (max-width: 1500px) {
                flex: 0 0 30%;
                max-width: 30%;
            }
            @media screen and (max-width: 1200px) {
                display: none;
            }
        }
    }
    &.auth-wizard {
        .auth-page-container {
            .auth-page-container-inner {
                @media screen and (min-width:1201px){
                    flex: 0 0 60%;
                    max-width: 60%;
                    .wizard-nav {
                        li {
                            a {
                                text-align: center;
                                display: block;
                                .wizard-icon-circle {
                                    margin: 0 auto;
                                }
                                span {
                                    display: block;
                                    margin-top: 15px;
                                }
                            }
                        }
                    }
                }
                .form-control {
                    min-width: unset;
                }
                @media screen and (min-width: 992px) and (max-width: 1450px) {
                    p,
                    label {
                        font-size: 14px;
                    }
                }
            }
            .auth-page-container-image {
                flex: 0 0 40%;
                max-width: 40%;
            }
        }
    }
}
