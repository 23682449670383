.global-search {
  --top-offset: calc((36px + 10px * 2) / 2);
  --side-padding: 32px;
  --filter-basis: 16vw;
  --filter-result-spacing: 0px;

  /* Leaves some space for effects like box shadows or outlines on the button. */
  --safe-area-for-results: 16px;

  display: flex;
  flex-direction: column;

  padding-left: var(--side-padding);
  padding-right: calc(var(--side-padding) - var(--safe-area-for-results));

  padding-top: 32px;
  padding-bottom: 32px;

  transform: translateY(var(--top-offset));

  .global-search-header {
    display: block;
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filter {
    display: flex;
    flex-direction: column;

    flex-basis: var(--filter-basis);
    flex-shrink: 0;
    flex-grow: 0;

    margin-right: var(--filter-result-spacing);

    .tag-selection {
      display: flex;
      flex-direction: column;

      > * {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        user-select: none;
        text-transform: uppercase;

        &,
        label,
        input {
          cursor: pointer;
        }
      }
    }
  }

  .results {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: unset;

    overflow: hidden;

    list-style: none;
    margin: 0px;
    padding: 0px;

    margin-top: 32px;

    padding-right: var(--safe-area-for-results);
    padding-bottom: var(--safe-area-for-results);

    display: flex;
    flex-direction: column;

    .global-search-result {
      margin-bottom: 8px;
    }

    .all-results-btn {
      align-self: stretch;
      margin-top: 8px;
      // background-color: $color-orange;
      // color: white;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .pagination {
    margin-top: 16px;
  }
  &.global-search-result-page {
    display: block;
  }
}

.global-search-page {
  --side-padding: 32px;
  margin-top: 32px;

  fieldset {
    margin-bottom: 32px;
  }

  .global-search {
    --side-padding: 0px;
    padding-top: 0px;
  }

  input {
    border-radius: 100px;
    padding: 1rem 1rem;
  }

  padding: 0px var(--side-padding);
}

.global-search-result {
  display: flex;
  flex-direction: row;

  overflow: hidden;

  .icon-container,
  .info-container {
    padding: 8px 16px;
  }

  .icon-container {
    flex-shrink: 0;
    flex-grow: 0;

    background-color: #efefef;
  }

  .info-container {
    flex-grow: 1;
    overflow: hidden;

    background-color: white;

    p {
      margin-bottom: 0px;
      color: gray;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      transition: 0.2s ease all;
    }
  }

  i,
  svg {
    color: $color-orange;
  }

  cursor: pointer;

  &:hover,
  &:focus-visible {
    outline: none;

    .info-container {
      transition: 0.2s ease all;
      color: white;
      background-color: $color-light-blue;
      p {
        color: white;
      }
    }
  }
}

.global-search-preview {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 999;

  min-height: 50vh;

  .global-search {
    background-color: $secondary;

    border-top: 1px solid rgba($secondary,0.2);

    /* Constrain the box shadow to the bottom edge */
    clip-path: inset(0px 0px -15px 0px);
    box-shadow: $shadow-default;
  }

  .backdrop {
    position: absolute;
    z-index: 99;

    backdrop-filter: blur(3px);

    height: 100vw;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .global-search,
  .global-search-page {
    --side-padding: 8vw;
  }

  .global-search {
    flex-direction: row;

    --filter-result-spacing: 16px;
    --top-offset: 0px;

    .results {
      flex-basis: 0;
      margin-top: 0px;

      .all-results-btn {
        align-self: flex-end;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .global-search,
  .global-search-page {
    --side-padding: 16vw;
    --filter-result-spacing: 32px;
  }
}

.global-search-result-page {
  .global-search-result {
    .info-container {
      background-color: #f1f9fb;
      &:hover,
      &:focus-visible {
        background-color: $color-light-blue;
      }
    }
  }
}
