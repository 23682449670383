.drop-upload {
    border: 2px dashed #dadada;
    &-preview {
        display: flex;
        align-items: center;
        text-align: center;
        background-color: #f1f9fb;
        justify-content: center;
        padding: 25px 35px;
        &-description {
            .upload-icon {
                display: inline-block;
                width: 50px;
                height: 50px;
                border: 1px solid $primary;
                background-color: white;
                border-radius: 50%;
                line-height: 48px;
                text-align: center;
                padding: 0;
                font-size: 18px;
                i,
                svg {
                    color: $primary;
                }
            }
            p {
                font-size: 20px;
                padding: 15px 0;
                margin: 0;
                font-weight: 700;
            }
        }
    }
    &-files {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        &-preview {
            text-align: left;
            &-selected {
            }
            .file-preview-list-item {
                border-bottom: 1px solid #dadada;
                padding: 3px 0;
                span {
                    max-width: 235px;
                }
                .btn {
                    margin: 0;
                    & > * {
                        top: auto;
                    }
                }
                .custom-control {
                    display: none;
                }
            }
            .btn {
                margin-bottom: 0;
            }
            div.list-informations {
                font-size: 12px;
                i,
                svg {
                    color: $color-orange;
                    margin-right: 10px;
                }
            }
        }
    }
}
.file-editor {
    background-color: #f4f4f4;
    padding: 20px 40px;
    &-preview {
        background-color: white;
        height: 170px;
        display: flex;
        color: $primary;
        font-size: 26px;
        text-transform: uppercase;
        justify-content: center;
        font-weight: 700;
        align-items: center;
        margin-bottom: 20px;
        img{
            max-height:100%;
        }
    }
}
