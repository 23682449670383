.misc-layout {
    background-image: url(../img/bg.jpg);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &-content{
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        text-align: center;
    }
}
