.matching {
    &-header {
        height: 350px;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        position: relative;
        &-effect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(255, 255, 255);
            background: linear-gradient(45deg, rgba(white, 1) 15%, rgba(255, 255, 255, 0) 70%);
        }
    }
    &-content {
        max-width: 85%;
        margin: -270px auto 0;
        color: $primary;
        .table {
            color: $primary;
        }
        .card {
            .card-header {
                margin: 0;
                &.highlighted {
                    .btn {
                        background-color: rgba($success, 0.15);
                        border: 1px solid $success !important;
                    }
                }
            }
            .card-body {
                padding: 30px;
            }
        }
        .filter-trigger {
            @include media-breakpoint-up(lg) {
                display: none;
            }
            margin-top: 20px;
            width: 100%;
        }
        @include media-breakpoint-down(lg) {
            .sticky-top {
                display: none !important;
            }
        }
    }
}

#matching-help-button{
    position: fixed;
    right:35px;
    bottom:35px;
    color:$primary;
    font-size: 35px;
    &:hover{
        color:$color-orange;
    }
}