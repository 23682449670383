a {
  text-decoration: none !important;
}

a[href]:not(.external-no-icon):not([href^="/"]):not([href^="https://dsag.kesslerdigital.cloud"]):not([href^="mailto:"]):not([href^="tel:"]):not([href^="https://dsag-files.kesslerdigital.cloud"]):not([href^="#"]):not([href^="javascript"]):not([href^="https://dsag.de"]):after
{
  content: "";
  width: 1em;
  height: 1em;
  margin-left: 0.3em;
  position: relative;
  top: 0.2em;
  display: inline-block;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>');
  background-size: contain;
}

.cursor-pointer {
  cursor: pointer;
}

img {
  max-width: 100%;
}

pre {
  font-family: $font-family-sans-serif;
}

.unstyled-btn {
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.hide-desktop {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

.hide-mobile {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.popover-wide {
  max-width: 65vw !important;
  h5 {
    font-size: 1em;
    margin-bottom: 0;
    margin-top: 8px;
  }
  .emoji-popover-row {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 1.5em;
    span {
      cursor: pointer;
    }
  }
}

.margin-mobile {
  @media screen and (max-width: 1500px) {
    margin-bottom: 15px;
  }
}

.hide-small-desktop {
  @media screen and (max-width: 1500px) {
    display: none !important;
  }
}

.sticky-top {
  z-index: 990;
}
.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  &.yellow {
    background-color: $color-yellow;
  }
  &.red {
    background-color: $color-red;
  }
  &.green {
    background-color: $color-green;
  }
  &.big {
    width: 11px;
    height: 11px;
  }
}

.btn {
  @media screen and (min-width: 1200px) and (max-width: 1560px) {
    font-size: 14px;
  }
}

.small-company-name {
  font-size: 24px;
  font-weight: 400;
  display: inline-block;
  margin-left: 10px;
}

.hr-light-blue {
  border-top: 1px solid #def1f6;
}

@media screen and (min-width: 1201px) and (max-width: 1450px) {
  body {
    font-size: 14px;
  }
  h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1200px) {
  body {
    font-size: 12px;
  }
  h1 {
    font-size: 26px;
  }
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
