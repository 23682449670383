.matching-content-logo {
    display: inline-block;
    width: 130px;
    height: 130px;
    background-color: white;
    position: relative;
    border-radius: 50%;
    box-shadow: $shadow-default;
    img {
        max-width: 85%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.matching-badges {
    .badge {
        i,
        svg {
            color: $color-orange;
        }
    }
}

.matching {
    h1 {
        font-weight: 700;
        text-transform: uppercase;
    }
    .social-icons {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        span {
            display: inline-block;
            margin-left: 10px;
            width: 45px;
            height: 45px;
            text-align: center;
            line-height: 46px;
            color: $color-orange;
            background-color: white;
            border-radius: 50%;
            font-size: 18px;
            transition: 0.3s ease all;
            cursor: pointer;
            &:hover {
                background-color: $color-orange;
                color: white;
            }
        }
    }
    .badge {
        &.highlighted {
            background-color: $color-orange;
            color: white;
        }
    }
    table {
        td {
            span.highlighted {
                color: $color-orange;
                font-weight: 600;
            }
        }
    }
    &-content {
        a[href]:not(.external-no-icon):not([href^="/"]):not([href^="https://dsag.kesslerdigital.cloud"]):not([href^="#"]):not([href^="https://dsag.de"]):after
        {
            top: 0;
            margin-left: 0.4em;
        }
    }
}
